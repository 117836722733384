import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MenuProps } from "../Menu";

interface HeaderMenuProps extends MenuProps {
  dispatch?: Dispatch<any>;
  inverted?: boolean;
}

export const HeaderMenu = ({ items, pathname, Link, inverted, dispatch }: HeaderMenuProps) =>
  <div className="bg-white pa2 w-100 flex justify-center">
    <div className="mw9 w-100 flex items-center flex-wrap justify-end">
      <div className="flex flex-wrap justify-end">
        {items.map((item) => {
          const active = (item.exact) ? pathname === item.path : pathname.startsWith(item.path);
          return <a
            className="pa2 ph3 ttu tracked blue"
            as={Link}
            name={item.name}
            to={item.path}
            key={item.path}
            href={item.path}
          >
              {item.name}
            </a>;
        })
        }
      </div>
    </div>
  </div>;

export default connect()(HeaderMenu);
