import { Link } from "gatsby";
import * as React from "react";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import "../css/global.css";
import "tachyons";
import "plyr/dist/plyr.css";
import "prismjs/themes/prism-okaidia.css";
import { Provider } from "react-redux";
import { ParallaxProvider } from "react-scroll-parallax";
import { store } from "../store";

export const menuItems = [
  { name: "Home", path: "/", exact: true, icon: "home", inverted: true },
  { name: "Musik", path: "/music", exact: false, icon: "music", inverted: true },
  { name: "Über uns", path: "/page/bio", exact: true, icon: "info circle" },
  { name: "Fotos", path: "/photos/", exact: false, icon: "newspaper" },
  { name: "Auftritte", path: "/shows/", exact: false, icon: "newspaper" },
  { name: "Kontakt", path: "/page/contact", exact: false, icon: "newspaper" },
];

export interface LayoutProps {
  location: {
    pathname: string;
  };
  children: any;
}

const Layout = (props: LayoutProps) => {
  const { pathname } = props.location;
  const isHome = pathname === "/";
  const year = new Date().getFullYear();

  return (
    <Provider store={store}>
      <ParallaxProvider>
        <div className="avenir relative min-vh-100">
          {/* Header */}
          {isHome ? null : <HeaderMenu
            Link={Link}
            pathname={pathname}
            items={menuItems}
          />}

          {/* Render children pages */}
          <div style={{ paddingBottom: 60 }}>
            {props.children}
          </div>

          {/* Footer */}
          <div className="absolute bottom-0 w-100 bg-dark-gray near-white tc cf">
              <p className="f7">Copyright © {year} Klangpiraten</p>
              <p className="f7">
                <a className="ph2 near-white" href="/page/datenschutz">Datenschutz</a>
    | <a className="ph2 near-white" href="/page/impressum">Impressum</a></p>
          </div>
        </div>
      </ParallaxProvider>
    </Provider>
  );
};

export default Layout;

export const withLayout = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class WithLayout extends React.Component<P & LayoutProps> {
    render() {
      return (
        <Layout location={this.props.location}>
          <WrappedComponent {...this.props} />
        </Layout>
      );
    }
  };
